import SearchIcon from "../assets/img/icons/customIcons/searchicondark.svg";
import CloseIcon from "../assets/img/icons/customIcons/cross-dark.svg";
import { DateRange } from "react-date-range";
import { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { axiosAccountInstance } from "api/Request";
import { makeStyles } from "@mui/styles";
import { experienceArray } from "helpers/utils";
import { formatDate } from "helpers/getDate";
export default function CustomFilter({
  list,
  isFilter,
  setIsFilter,
  handleEmployeeSearchOnChange,
  handleSearch,
  type,
  handleClearDate,
  handleClearDateBookEmployee,
  handleSearchOnSelect,
  handleSearchOnChange,
  tableHeight,
  minHeight,
}) {
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: new Date(""),
      key: "selection",
    },
  ]);

  const [oneMonth, setOneMonth] = useState([
    {
      startDate: new Date(formatDate()),
      endDate: new Date(formatDate(1)),
      key: "selection",
    },
  ]);
  const useStyles = makeStyles((theme) => ({
    autocomplete: {
      backgroundColor: "white",
      borderRadius: "100px",
      "& .MuiInputBase-root.MuiInput-root:after": {
        borderBottom: "0px",
      },
      "& .MuiInputBase-root.MuiInput-root:before": {
        borderBottom: "0px",
      },
      "& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before": {
        borderBottom: "0px",
      },
      "& .MuiAutocomplete-popupIndicator": {
        display: "none",
      },
    },
    searchInput: {
      paddingLeft: "10px",
      marginRight: "10px",
      paddingTop: "2px",
    },
  }));
  const classes = useStyles();
  const [tablesHeight, setTablesHeight] = useState(tableHeight);
  const [options, setOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [companyValue, setCompanyValue] = useState(null);
  const inputElem = useRef(null);
  const [isEnableClearDate, setIsEnableClearDate] = useState(false);
  const [, setSearchText] = useState("");

  const handleChange = (event) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    // handleEmployeeSearchOnChange(event);
    // Delayed API call after 5000ms
    setTimeout(() => {
      fetchOptions(newSearchText);
    }, 500);
  };
  const handleChangeCompanyName = (event) => {
    console.log(event);
    setTimeout(() => {
      fetchOptionsCompany(event.target.value);
    }, 500);
  };
  const fetchOptions = async (searchData) => {
    try {
      axiosAccountInstance
        .get(`${process.env.REACT_APP_API_URL}list_skills?filter=${searchData}`)

        .then((result) => {
          setOptions(result?.data?.body?.data);
        });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };
  const fetchOptionsCompany = async (searchData) => {
    try {
      axiosAccountInstance
        .get(`${process.env.REACT_APP_API_URL}list_company?filter=${searchData}`)
        .then((result) => {
          setCompanyOptions(result?.data?.body?.data);
        });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const clearDate = () => {
    setState([
      {
        startDate: null,
        endDate: new Date(""),
        key: "selection",
      },
    ]);
    handleClearDate();
  };
  const clearDateBookEmployee = () => {
    setIsEnableClearDate(false);
    setOneMonth([
      {
        startDate: new Date(formatDate()),
        endDate: new Date(formatDate(1)),
        key: "selection",
      },
    ]);
    handleClearDateBookEmployee();
  };

  const debounce = (func, wait) => {
    let timeout;

    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };
  // eslint-disable-next-line
  const handleEmployeeSearchOnChangeName = useCallback(
    debounce((inputVal) => handleSearchOnChange("name", inputVal), 1000),

    []
  );

  useEffect(() => {
    setTablesHeight(tableHeight);
  }, [tableHeight]);

  return (
    <>
      <div
        style={{ height: tablesHeight, minHeight: minHeight }}
        className="filter-boxes"
      >
        <div className="title-area d-flex justify-content-between mb-3">
          <h5>Search </h5>
          <span style={{ cursor: "pointer" }}>
            <img
              alt=""
              onClick={() => setIsFilter(false)}
              src={CloseIcon}
              width={"18px"}
            />
          </span>
        </div>
        {/* <form
        // onSubmit={handleSearch}
        > */}
          {type !== "manager" && type !== "employee" && (
            <>
              <div className="form-group mb-3">
                <label>Search by skill</label>
                <div className="customsearch">
                  <span className="searchicon">
                    <img alt="" src={!value ? SearchIcon : ""} width={"16px"} />
                  </span>
                  <Autocomplete
                    className={`${classes.autocomplete} form-control`}
                    name="search"
                    value={value}
                    onClear={() => console.log("close me")}
                    onChange={(event, newValue) => {
                      if (newValue === null) {
                        setValue(null);
                        setOptions([]);
                        handleSearchOnSelect("");
                        return;
                      }
                      if (typeof newValue === "string") {
                        setValue({
                          domain: newValue.domain,
                        });
                        handleEmployeeSearchOnChange(
                          "search",
                          newValue?.domain
                        );
                      } else if (newValue && newValue.domain) {
                        // Create a new value from the user input
                        setValue({
                          domain: newValue.domain,
                        });
                        handleEmployeeSearchOnChange(
                          "search",
                          newValue?.domain
                        );
                      } else {
                        setValue(newValue.domain);
                        handleEmployeeSearchOnChange(
                          "search",
                          newValue?.domain
                        );
                      }
                      handleSearchOnSelect(newValue?.domain);
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    // id="free-solo-with-text-demo"
                    options={options}
                    getOptionLabel={(option) => {
                      if (typeof option?.domain === "string") {
                        return option?.domain;
                      }

                      return option.domain;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.domain}</li>
                    )}
                    renderInput={(params) => (
                      <div className="d-flex">
                        <TextField
                          placeholder="Search"
                          {...params}
                          name="search"
                          onChange={handleChange}
                          variant="standard"
                        />
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="form-group mb-3">
                <label>Search by experience</label>
                <div className="customsearch">
                  <span className="searchicon">
                    {/* <img alt="" src={SearchIcon} width={"16px"} /> */}
                  </span>
                  <select
                    style={{ fontSize: "1rem", paddingLeft: "4px" }}
                    name="experience"
                    onChange={(e) => {
                      console.log("exp ", e.target.value);
                      handleSearchOnSelect(e.target.value, "experience");
                    }}
                    className="form-control"
                  >
                    <option value={""}>Select</option>
                    {experienceArray.map((itm) => (
                      <option>{itm?.label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}

          {type === "outbound" && (
            <>
              <div className="form-group mb-3">
                <label>Search by company name</label>
                <div className="customsearch">
                  <span className="searchicon">
                    <img
                      alt=""
                      src={!companyValue ? SearchIcon : ""}
                      width={"16px"}
                    />
                  </span>
                  <Autocomplete
                    className={`${classes.autocomplete} form-control`}
                    name="parentCompany"
                    value={companyValue}
                    onChange={(event, newValue) => {
                      if (newValue === null) {
                        setCompanyValue(null);
                        setCompanyOptions([]);
                        handleSearchOnSelect("", "parentCompany");
                        return;
                      }
                      if (typeof newValue === "string") {
                        setCompanyValue({
                          companyName: newValue?.companyName,
                        });
                        handleEmployeeSearchOnChange(
                          "parentCompany",
                          newValue?.companyName
                        );
                      } else if (newValue && newValue.companyName) {
                        // Create a new value from the user input
                        setCompanyValue({
                          companyName: newValue.companyName,
                        });
                        handleEmployeeSearchOnChange(
                          "parentCompany",
                          newValue?.companyName
                        );
                      } else {
                        setCompanyValue(newValue.companyName);
                        handleEmployeeSearchOnChange(
                          "parentCompany",
                          newValue?.companyName
                        );
                      }
                      handleSearchOnSelect(
                        newValue?.companyName,
                        "parentCompany"
                      );
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    // id="free-solo-with-text-demo"
                    options={companyOptions}
                    getOptionLabel={(option) => {
                      if (typeof option?.companyName === "string") {
                        return option?.companyName;
                      }

                      return option.companyName;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.companyName}</li>
                    )}
                    renderInput={(params) => (
                      <div className="d-flex">
                        <TextField
                          placeholder="Search"
                          {...params}
                          name="parentCompany"
                          onChange={handleChangeCompanyName}
                          variant="standard"
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="form-group ">
                <div className="d-flex justify-content-between">
                  <label>Select date</label>
                  <p
                    onClick={clearDate}
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      cursor: "pointer",
                      marginBottom: "0",
                    }}
                  >
                    Clear date
                  </p>
                </div>

                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => {
                    handleSearchOnSelect(item.selection, "date");
                    setState([item.selection]);
                    console.log(item.selection);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                  startDatePlaceholder="From Date"
                  endDatePlaceholder="To Date"
                />
              </div>
            </>
          )}

          {type === "inbound" && (
            <>
              <div className="form-group mb-3">
                <label>Search by company name</label>
                <div className="customsearch">
                  <span className="searchicon">
                    <img
                      alt=""
                      src={!companyValue ? SearchIcon : ""}
                      width={"16px"}
                    />
                  </span>
                  <Autocomplete
                    className={`${classes.autocomplete} form-control`}
                    name="bookedCompany"
                    value={companyValue}
                    onChange={(event, newValue) => {
                      if (newValue === null) {
                        setCompanyValue(null);
                        setCompanyOptions([]);
                        handleSearchOnSelect("", "bookedCompany");
                        return;
                      }
                      if (typeof newValue === "string") {
                        setCompanyValue({
                          companyName: newValue.companyName,
                        });
                        handleEmployeeSearchOnChange(
                          "bookedCompany",
                          newValue?.companyName
                        );
                      } else if (newValue && newValue.companyName) {
                        // Create a new value from the user input
                        setCompanyValue({
                          companyName: newValue.companyName,
                        });
                        handleEmployeeSearchOnChange(
                          "bookedCompany",
                          newValue?.companyName
                        );
                      } else {
                        setCompanyValue(newValue.companyName);
                        handleEmployeeSearchOnChange(
                          "bookedCompany",
                          newValue?.companyName
                        );
                      }
                      handleSearchOnSelect(
                        newValue?.companyName,
                        "bookedCompany"
                      );
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    // id="free-solo-with-text-demo"
                    options={companyOptions}
                    getOptionLabel={(option) => {
                      if (typeof option?.companyName === "string") {
                        return option?.companyName;
                      }

                      return option.companyName;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.companyName}</li>
                    )}
                    renderInput={(params) => (
                      <div className="d-flex">
                        <TextField
                          placeholder="Search"
                          {...params}
                          name="bookedCompany"
                          onChange={handleChangeCompanyName}
                          variant="standard"
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="form-group ">
                <div className="d-flex justify-content-between">
                  <label>Select date</label>
                  <p
                    onClick={clearDate}
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      cursor: "pointer",
                      marginBottom: "0",
                    }}
                  >
                    Clear date
                  </p>
                </div>
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => {
                    handleSearchOnSelect(item.selection, "date");
                    setState([item.selection]);
                    console.log("selection", item.selection);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                  startDatePlaceholder="From Date"
                  endDatePlaceholder="To Date"
                />
              </div>
            </>
          )}

          {type === "manager" && (
            <div className="form-group mb-3">
              <label>Search by name</label>
              <div className="customsearch">
                <span className="searchicon">
                  <img
                    // style={{ cursor: "pointer" }}
                    alt=""
                    // onClick={handleSearch}
                    src={SearchIcon}
                    width={"16px"}
                  />
                </span>
                <input
                  style={{ fontSize: "1rem" }}
                  name="search"
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  ref={inputElem}
                  onChange={() =>
                    handleEmployeeSearchOnChangeName(inputElem.current?.value)
                  }
                />
              </div>
            </div>
          )}

          {type === "employee" && (
            <>
              <div className="form-group mb-3">
                <label>Search by name</label>
                <div className="customsearch">
                  <span className="searchicon">
                    <img
                      // style={{ cursor: "pointer" }}
                      alt=""
                      // onClick={handleSearch}
                      src={SearchIcon}
                      width={"16px"}
                    />
                  </span>
                  <input
                    style={{ fontSize: "1rem" }}
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    ref={inputElem}
                    onChange={() =>
                      handleEmployeeSearchOnChangeName(inputElem.current?.value)
                    }
                    // onChange={(e) =>
                    //   handleEmployeeSearchOnChangeName("search", e.target.value)
                    // }
                  />
                </div>
              </div>

              <div className="form-group mb-3">
                <label>Search by skill</label>
                <div className="customsearch">
                  <span className="searchicon">
                    <img alt="" src={!value ? SearchIcon : ""} width={"16px"} />
                  </span>
                  <Autocomplete
                    className={`${classes.autocomplete} form-control`}
                    name="search"
                    value={value}
                    onChange={(event, newValue) => {
                      if (newValue === null) {
                        setValue(null);
                        setOptions([]);
                        handleSearchOnSelect("");
                        handleEmployeeSearchOnChange("search", "");

                        return;
                      }
                      if (typeof newValue === "string") {
                        setValue({
                          domain: newValue.domain,
                        });
                        handleEmployeeSearchOnChange(
                          "search",
                          newValue?.domain
                        );
                      } else if (newValue && newValue.domain) {
                        // Create a new value from the user input
                        setValue({
                          domain: newValue.domain,
                        });
                        handleEmployeeSearchOnChange(
                          "search",
                          newValue?.domain
                        );
                      } else {
                        setValue(newValue.domain);
                        handleEmployeeSearchOnChange(
                          "search",
                          newValue?.domain
                        );
                      }
                      handleSearchOnSelect(newValue?.domain);
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    // id="free-solo-with-text-demo"
                    options={options}
                    getOptionLabel={(option) => {
                      if (typeof option?.domain === "string") {
                        return option?.domain;
                      }

                      return option.domain;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.domain}</li>
                    )}
                    renderInput={(params) => (
                      <div className="d-flex">
                        <TextField
                          placeholder="Search"
                          {...params}
                          name="search"
                          onChange={handleChange}
                          variant="standard"
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="form-group mb-3">
                <label>Search by experience</label>
                <div className="customsearch">
                  <span className="searchicon">
                    {/* <img alt="" src={SearchIcon} width={"16px"} /> */}
                  </span>
                  <select
                    style={{ fontSize: "1rem", paddingLeft: "4px" }}
                    name="experience"
                    onChange={(e) => {
                      console.log("exp ", e.target.value);
                      handleSearchOnSelect(e.target.value, "experience");
                    }}
                    className="form-control"
                  >
                    <option value={""}>Select</option>
                    {experienceArray.map((itm) => (
                      <option>{itm?.label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}

          {type === "bookemployee" && (
            <>
              <div className="form-group mb-3">
                <label>Search by company name</label>
                <div className="customsearch">
                  <span className="searchicon">
                    <img
                      alt=""
                      src={!companyValue ? SearchIcon : ""}
                      width={"16px"}
                    />
                  </span>
                  <Autocomplete
                    className={`${classes.autocomplete} form-control`}
                    name="companyName"
                    value={companyValue}
                    onChange={(event, newValue) => {
                      if (newValue === null) {
                        setCompanyValue(null);
                        setCompanyOptions([]);
                        handleSearchOnSelect("", "companyName");
                        return;
                      }
                      if (typeof newValue === "string") {
                        setCompanyValue({
                          companyName: newValue?.companyName,
                        });
                        handleEmployeeSearchOnChange(
                          "companyName",
                          newValue?.companyName
                        );
                      } else if (newValue && newValue.companyName) {
                        // Create a new value from the user input
                        setCompanyValue({
                          companyName: newValue.companyName,
                        });
                        handleEmployeeSearchOnChange(
                          "companyName",
                          newValue?.companyName
                        );
                      } else {
                        setCompanyValue(newValue.companyName);
                        handleEmployeeSearchOnChange(
                          "companyName",
                          newValue?.companyName
                        );
                      }
                      handleSearchOnSelect(
                        newValue?.companyName,
                        "companyName"
                      );
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    // id="free-solo-with-text-demo"
                    options={companyOptions}
                    getOptionLabel={(option) => {
                      if (typeof option?.companyName === "string") {
                        return option?.companyName;
                      }

                      return option.companyName;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.companyName}</li>
                    )}
                    renderInput={(params) => (
                      <div className="d-flex">
                        <TextField
                          placeholder="Search"
                          {...params}
                          name="companyName"
                          onChange={handleChangeCompanyName}
                          variant="standard"
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="form-group">
                {/* <label>Select date</label> */}
                <div className="d-flex justify-content-between">
                  <label>Select date</label>
                  {isEnableClearDate ? (
                    <p
                      onClick={clearDateBookEmployee}
                      style={{
                        fontSize: "13px",
                        fontWeight: "600",
                        cursor: "pointer",
                        marginBottom: "0",
                      }}
                    >
                      Clear date
                    </p>
                  ) : (
                    <p
                      // onClick={clearDateBookEmployee}
                      style={{
                        color: "gray",
                        fontSize: "13px",
                        // fontWeight: "300",
                        // cursor: "pointer",
                        marginBottom: "0",
                      }}
                    >
                      Clear date
                    </p>
                  )}
                </div>
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => {
                    setIsEnableClearDate(true);
                    handleSearchOnSelect(item.selection, "date");
                    setOneMonth([item.selection]);
                    console.log(item.selection);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={oneMonth}
                  startDatePlaceholder="From Date"
                  endDatePlaceholder="To Date"
                />
              </div>
            </>
          )}
           {type === "resume_requests" && (
            <>
              <div className="form-group mb-3">
                <label>Search by company name</label>
                <div className="customsearch">
                  <span className="searchicon">
                    <img
                      alt=""
                      src={!companyValue ? SearchIcon : ""}
                      width={"16px"}
                    />
                  </span>
                  <Autocomplete
                    className={`${classes.autocomplete} form-control`}
                    name="companyName"
                    value={companyValue}
                    onChange={(event, newValue) => {
                      if (newValue === null) {
                        setCompanyValue(null);
                        setCompanyOptions([]);
                        handleSearchOnSelect("", "companyName");
                        return;
                      }
                      if (typeof newValue === "string") {
                        setCompanyValue({
                          companyName: newValue?.companyName,
                        });
                        handleEmployeeSearchOnChange(
                          "companyName",
                          newValue?.companyName
                        );
                      } else if (newValue && newValue.companyName) {
                        // Create a new value from the user input
                        setCompanyValue({
                          companyName: newValue.companyName,
                        });
                        handleEmployeeSearchOnChange(
                          "companyName",
                          newValue?.companyName
                        );
                      } else {
                        setCompanyValue(newValue.companyName);
                        handleEmployeeSearchOnChange(
                          "companyName",
                          newValue?.companyName
                        );
                      }
                      handleSearchOnSelect(
                        newValue?.companyName,
                        "companyName"
                      );
                    }}
                    selectOnFocus
                    handleHomeEndKeys
                    // id="free-solo-with-text-demo"
                    options={companyOptions}
                    getOptionLabel={(option) => {
                      if (typeof option?.companyName === "string") {
                        return option?.companyName;
                      }

                      return option.companyName;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.companyName}</li>
                    )}
                    renderInput={(params) => (
                      <div className="d-flex">
                        <TextField
                          placeholder="Search"
                          {...params}
                          name="companyName"
                          onChange={handleChangeCompanyName}
                          variant="standard"
                        />
                      </div>
                    )}
                  />
                </div>
              </div>
             
            </>
          )}
        
      </div>
    </>
  );
}
