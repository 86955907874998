const ScheduledInterviewReducer = (state = { loader: false, result: [], error: null }, action) => {
    switch (action.type) {
      case 'SCHEDULED_INTERVIEW_LIST_BEGINS':
        return {
          loader: true,
          result: state.result,
          error: null,
        };
      case 'SCHEDULED_INTERVIEW_LIST_SUCCESS':
        debugger
        return {
          loader: false,
          result: action?.payload,
          error: null,
        };
      case 'SCHEDULED_INTERVIEW_LIST_FAILURE':
        return {
          loader: false,
          result: {},
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default ScheduledInterviewReducer;