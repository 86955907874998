// reactstrap components
import { Card, Table } from "reactstrap";
import moment from "moment";
import React from "react";
import { uniqueDate } from "helpers/getDate";
import { capitilizeFirstAlphabet } from "helpers/stringManupulate";

const ExpiringBookingsTable = ({ data = [], columns, viewButton, loader }) => {
  const handleViewBooking = (item) => {
    viewButton.viewHandler(item);
  };

  const backgroundColor = (item) => {
    if (item.duration) {
      const nowDate = new Date();
      const toDate = new Date(item.duration.toDate);
      const dateDifference = toDate - nowDate;

      const fiveDays = 3600 * 24 * 5 * 1000;
      const tenDays = 3600 * 24 * 10 * 1000;

      if (dateDifference < 0) return;

      if (dateDifference < fiveDays) {
        return "#fcece8";
      }
      if (dateDifference > fiveDays && dateDifference < tenDays) {
        return "#f9fcd6";
      }
    }
  };

  const daysTillExpiry = (item) => {
    if (item.duration) {
      const nowDate = new Date();
      const toDate = new Date(item.duration.toDate);

      const dateDifference = toDate - nowDate;
      const days = Math.ceil(dateDifference / (3600 * 24 * 1000));
      return days;
    }
  };

  return (
    <>
      {/* Page content */}
      {/* Table */}
      <Card className="shadow mt-1">
        <Table className="table-flush" hover responsive>
          <thead>
            <tr>
              {columns?.map((item, key) => (
                <th key={key}>{item.columnName}</th>
              ))}
            </tr>
          </thead>
          {!loader && data.length > 0 && (
            <tbody>
              {data.map((item, key) => (
                <tr
                  onClick={() => handleViewBooking(item)}
                  key={key}
                  style={{
                    backgroundColor: backgroundColor(item),
                    cursor: "pointer",
                  }}
                >
                  {columns.map((column) => (
                    <>
                      {column.type === "capitalize" && (
                        <td key={column.columnTag}>
                          {capitilizeFirstAlphabet(item[column.columnTag])}
                        </td>
                      )}
                      {column.type === "date" && (
                        <td key={column.columnTag}>
                          {uniqueDate(item[column.columnTag].fromDate)}{" "}
                          -{" "}
                          {uniqueDate(item[column.columnTag].toDate)}
                        </td>
                      )}
                      {column.type === "text" && (
                        <td key={column.columnTag}>{item[column.columnTag]}</td>
                      )}
                      {column.type === "paymentMapping" && (
                        <td key={column.columnTag}>
                          <p className="h5 font-weight-normal mb-0">
                            {/* {item[column.columnTag] &&
                            capitilizeFirstAlphabet(
                              item[column.columnTag].toString()
                            )}{" "}
                          {item["currencyCode"] &&
                            capitilizeFirstAlphabet(
                              item["currencyCode"].toString()
                            )} */}
                            {item["currencyCode"] &&
                              (() => {
                                const currencyCode =
                                  item["currencyCode"].toString();
                                let currencySymbol;

                                if (currencyCode === "USD") {
                                  currencySymbol = "$";
                                } else if (currencyCode === "EUR") {
                                  currencySymbol = "€";
                                } else if (currencyCode === "INR") {
                                  currencySymbol = "₹";
                                } else {
                                  currencySymbol = currencyCode;
                                }

                                return currencySymbol;
                              })()}

                            {item[column.columnTag] &&
                              capitilizeFirstAlphabet(
                                item[column.columnTag].toString()
                              )}
                          </p>
                        </td>
                      )}
                      {column.columnTag === "daysTillExpiry" && (
                        <td key={column.columnTag}>{daysTillExpiry(item)}</td>
                      )}
                    </>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </Table>
        {!loader && data.length === 0 && (
          <div className="nodata-table">
            <img
              alt="..."
              src={
                require("../../../../../assets/img/icons/customIcons/nodata.gif")
                  .default
              }
            />
          </div>
        )}
        {loader && (
          <div className="table-loader">
            <img
              alt="..."
              src={
                require("../../../../../assets/img/icons/customIcons/loader.gif")
                  .default
              }
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default ExpiringBookingsTable;
