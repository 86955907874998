// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormFeedback,
  Label,
} from "reactstrap";
import countryList from "react-select-country-list";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import history from "helpers/History";
import { axiosAccountInstance } from "api/Request";
// import countryList from "react-select-country-list";
import { useDispatch, useSelector } from "react-redux";
import toast from "../../components/Toastify";
import { fetchUserData } from "helpers/storageData";
import PhoneNumberComponent from "components/phoneNumberInput/PhoneNumberComponent";
import { setProfileUpdate } from "redux/actions/common/ProfileUpdateAction";
const Profile = ({ match }) => {
  const location = useLocation();
  const userData = location.state.email;
  console.log("locationnnn", location);

  const [disable, setDisable] = useState(true);
  // storing errors
  const [errors, setErrors] = useState(null);
  const [countryValidation, setcountryValidation] = useState("");
  const dispatch = useDispatch();
  const [userID, setUserID] = useState("");
  // const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [address, setAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isInvalidPhone, setIsInvalidPhone] = useState(null);
  const [gstNumber, setGstNumber] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [country, setCountry] = useState("");

  const options = useMemo(() => countryList().getData(), []);

  // const [value, setValue] = useState("");
  // const options = useMemo(() => countryList().getData(), []);
  const updatePasswordHandler = () => {
    history.push("/admin/login-update-password");
  };

  const role = localStorage.getItem("user");
  const userRole = JSON.parse(role).role;
  console.log(userRole);
  // if(userRole==="super_admin"){
  //   setDisable(true)
  // }
  const submitLoader = useSelector(
    (state) => state.submitRequestReducer.loader
  );
  // storing the data in case of edit-page
  useEffect(() => {
    if (userData) {
      axiosAccountInstance.get(`/company/${userData}`).then((res) => {
        const requestData = res.data.body.data;
        // setFullName(requestData?.name);
        setFirstName(requestData?.firstName);
        setLastName(requestData?.lastName);
        setEmail(requestData?.email);
        setMobileNumber(requestData?.contactNumber);
        setDesignation(requestData?.designation);
        setAddress(requestData?.address);
        setCountry({
          label: requestData?.countryName,
          value: requestData?.countryCode,
        });
        setGstNumber(requestData?.gstNumber);
        setRegistrationNumber(requestData?.registerNumber);
        setUserID(requestData?.userId);
        setCompanyName(requestData?.companyName);
      });
    }
  }, [userData]);

  const validation = () => {
    const errorsFound = {};
    // if(!companyName)  errorsFound["companyName"]="Oops! please add company name"
    // if (!fullName) errorsFound["fullName"] = "Name is required";
    if (!firstName) errorsFound["firstName"] = "First Name is required";
    const nameRegex = /^[A-Za-z ]{1,50}$/;
    if (!nameRegex.test(firstName)) {
      errorsFound["firstName"] =
        "Allows only alphabetical characters (1-50 characters).";
    }
    if (!nameRegex.test(lastName)) {
      errorsFound["lastName"] =
        "Allows only alphabetical characters (1-50 characters).";
    }
    if (!lastName) errorsFound["lastName"] = "Last Name is required";
    if (emailValidation(email) === false) {
      errorsFound["email"] = "Please enter a valid email address";
    }
    if (!email || email === "") errorsFound["email"] = "Email is required";
    if (!mobileNumber)
      errorsFound["mobileNumber"] = "Mobile number is required";
    if (mobileNumber && isInvalidPhone)
      errorsFound["mobileNumberVal"] = "Mobile number is invalid";

    if (userRole !== "super_admin") {
      if (!designation) errorsFound["designation"] = "Designation is required";
      if (!address) errorsFound["address"] = "Address is required";

      if (!registrationNumber)
        errorsFound["registrationNumber"] = "Registration number is required";
      // const registerNumberRegex = /^[a-zA-Z0-9]+$/;
      // if (!registerNumberRegex.test(registrationNumber)) {
      //   errorsFound["registrationNumber"] = "Allows only alphanumeric characters.";
      // }
      
      if (!companyName) errorsFound["companyName"] = "Company name is required";
    }

    // if (!country)
    //   errorsFound["country"] = "Oops! please add country";
    // if (!gstNumber) errorsFound["gstNumber"] = "Oops! please add GST number";
    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const emailValidation = (data) => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!data || regex.test(data) === false) {
      setErrors({ ...errors, email: "Please enter a valid mail" });
      return false;
    } else {
      setErrors({ ...errors, email: "" });
      return true;
    }
  };

  // const mobileNumbervalidation = (data) => {
  //   const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

  //   if (!data || regex.test(data) === false || data.length < 6) {
  //     setErrors({
  //       ...errors,
  //       mobileNumber: "Please enter a valid mobile number",
  //     });
  //     return false;
  //   } else {
  //     setErrors({ ...errors, mobileNumber: "" });
  //     return true;
  //   }
  // };

  const handleMobileValidation = (values, countrys) => {
    if (countrys.format.match(/\./g).length === values.length) {
      setIsInvalidPhone(false);
      return true;
    } else {
      setIsInvalidPhone(true);
      return false;
    }
  };

  const saveHandler = (e) => {
    e.preventDefault();

    const isValid = validation();

    if (isValid) {
      dispatch({
        type: "REQUEST_BEGINS",
        loader: true,
        result: null,
      });

      let data;

      if (userRole !== "super_admin") {
        data = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          contactNumber: mobileNumber,
          designation: designation,
          address: address,
          countryCode: country.value,
          countryName: country.label,
          gstNumber: gstNumber,
          registerNumber: registrationNumber,
          companyName: companyName,
        };
      } else {
        data = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          contactNumber: mobileNumber,
          designation: designation,
        };
      }

      if (userID) {
        axiosAccountInstance
          .put(`/companyupdate?id=${userID}`, data)
          .then((res) => {
            const data = fetchUserData();
            const finalLSUserData = {
              ...data,
              ...res.data.body.data,
            };
            localStorage.setItem("user", JSON.stringify(finalLSUserData));
            dispatch(setProfileUpdate(finalLSUserData));
            dispatch({
              type: "REQUEST_SUCCESS",
              loader: true,
              // result: action.payload,
            });
            toast.success(res.data.body.message);
            setDisable(true);
          })
          .catch((err) => {
            dispatch({
              type: "REQUEST_FAILURE",
              loader: false,
              result: null,
            });
            toast.error(err.response.data.body.message);
          });
      }
    }
  };

  // const changeHandler = (value) => {
  //   console.log(value);
  //   setCountry(value.target.value);
  //   setErrors({...errors, country:null})
  // };
  const changeHandler = (value) => {
    setCountry(value);
    setcountryValidation(false);
  };
  const phonenumberInput = (e) => {
    setMobileNumber(e);
    setErrors({ ...errors, mobileNumber: null, mobileNumberVal: null });
  };
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--9" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="6">
                    <h3 className="mb-0">My Account</h3>
                  </Col>
                  <Col className="d-sm-flex justify-content-end" sm="6">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setDisable(false);
                      }}
                      size="sm"
                    >
                      Edit Profile
                    </Button>
                    <Button
                      color="primary"
                      onClick={updatePasswordHandler}
                      size="sm"
                    >
                      Update Password
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            First Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                              setErrors({ ...errors, firstName: null });
                            }}
                            disabled={disable}
                            type="text"
                            invalid={!!errors && !!errors.firstName}
                          />
                          <FormFeedback
                            className={errors?.firstName && "invalid-field"}
                          >
                            {errors?.firstName}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Last Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Last name"
                            value={lastName}
                            onChange={(e) => {
                              setLastName(e.target.value);
                              setErrors({ ...errors, lastName: null });
                            }}
                            disabled={disable}
                            type="text"
                            invalid={!!errors && !!errors.lastName}
                          />
                          <FormFeedback
                            className={errors?.lastName && "invalid-field"}
                          >
                            {errors?.lastName}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email Address<span style={{ color: "red" }}>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="admin@example.com"
                            type="email"
                            value={email}
                            disabled={true}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setErrors({ ...errors, email: null });
                              emailValidation(e.target.value);
                            }}
                            invalid={!!errors && !!errors.email}
                          />

                          <FormFeedback
                            className={errors?.email && "invalid-field"}
                          >
                            {errors?.email}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Mobile Number<span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <Input
                            className="form-control-alternative"
                            defaultValue="Lucky"
                            id="input-first-name"
                            placeholder="Mobile Number"
                            type="text"
                            value={mobileNumber}
                            disabled={disable}
                            onChange={(e) => {
                              setMobileNumber(e.target.value);
                              setErrors({ ...errors, mobileNumber: null });
                              mobileNumbervalidation(e.target.value);
                            }}
                            invalid={errors && errors.mobileNumber}
                          /> */}
                          <PhoneNumberComponent
                            disabled={disable}
                            name="mobile"
                            id="mobile"
                            country={"in"}
                            value={mobileNumber}
                            handleChange={phonenumberInput}
                            handleMobileValidation={handleMobileValidation}
                          />
                          <FormFeedback
                            className={
                              (!!errors?.mobileNumber ||
                                !!errors?.mobileNumberVal) &&
                              "invalid-field"
                            }
                          >
                            {errors?.mobileNumber || errors?.mobileNumberVal}
                          </FormFeedback>
                          {/* <FormFeedback
                            className={
                              !!errors?.mobileNumberVal && "invalid-field"
                            }
                          >
                            {errors?.mobileNumberVal }
                          </FormFeedback> */}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Designation<span style={{ color: "red" }}>*</span>
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue="Jesse"
                            id="input-last-name"
                            placeholder="Designation"
                            type="text"
                            value={designation}
                            disabled={disable}
                            onChange={(e) => {
                              setDesignation(e.target.value);
                              setErrors({ ...errors, designation: null });
                            }}
                            invalid={!!errors && !!errors.designation}
                          />
                          <FormFeedback
                            className={errors?.designation && "invalid-field"}
                          >
                            {errors?.designation}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {userRole !== "super_admin" ? (
                    <>
                      <hr className="my-4" />

                      <h6 className="heading-small text-muted mb-4">
                        Company information
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                              >
                                Company<span style={{ color: "red" }}>*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue="Jesse"
                                id="input-last-name"
                                placeholder="Company name"
                                type="text"
                                value={companyName}
                                disabled={userRole=="company_manager" ? true :disable}
                                onChange={(e) => {
                                  setCompanyName(e.target.value);
                                  setErrors({ ...errors, companyName: null });
                                }}
                                invalid={!!errors && !!errors.companyName}
                              />
                              <FormFeedback
                                className={
                                  errors?.companyName && "invalid-field"
                                }
                              >
                                {errors?.companyName}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                Registration Number
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-postal-code"
                                placeholder="Registration Number"
                                type="text"
                                value={registrationNumber}
                                disabled={userRole=="company_manager" ? true :disable}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Check if the value is alphanumeric
                                  if (/^[a-zA-Z0-9]*$/.test(value)) {
                                    console.log("registrationNumber",value)
                                    setRegistrationNumber(value);
                                    setErrors({
                                      ...errors,
                                      registrationNumber: null,
                                    });
                                  }
                                }}
                                // onChange={(e) => {
                                //   const value = e.target.value;
                                //   setRegistrationNumber(value);
                                //   setErrors({
                                //     ...errors,
                                //     registrationNumber: null,
                                //   });
                                // }}
                                invalid={
                                  !!errors && !!errors.registrationNumber
                                }
                              />
                              <FormFeedback
                                className={
                                  errors?.registrationNumber && "invalid-field"
                                }
                              >
                                {errors?.registrationNumber}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <Label
                                // style={{ fontSize: "10px" }}
                                for="employeeName"
                                // className="text-uppercase"
                                className="form-control-label"
                              >
                                Country<span style={{ color: "red" }}>*</span>
                              </Label>
                              <Select
                                value={options.filter(
                                  (option) => option.value === country.value
                                )}
                                onChange={changeHandler}
                                options={options}
                                isDisabled={userRole=="company_manager" ? true :disable}
                               
                              />

                              {countryValidation !== "" && (
                                <span style={{ fontSize: "80%", color: "red" }}>
                                  {countryValidation}
                                </span>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-address"
                              >
                                Address<span style={{ color: "red" }}>*</span>
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-address"
                                placeholder="Company Address"
                                value={address}
                                disabled={userRole=="company_manager" ? true :disable}
                                onChange={(e) => {
                                  setAddress(e.target.value);
                                  setErrors({ ...errors, address: null });
                                }}
                                type="textarea"
                                invalid={!!errors && !!errors.address}
                              />
                              <FormFeedback
                                className={errors?.address && "invalid-field"}
                              >
                                {errors?.address}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {/* <hr className="my-4" /> */}
                  {/* Description */}
                  {/* <h6 className="heading-small text-muted mb-4">About me</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>About Me</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="A few words about you ..."
                        rows="4"
                        defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                        Open Source."
                        type="textarea"
                      />
                    </FormGroup>
                  </div> */}
                </Form>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    disabled={disable}
                    onClick={saveHandler}
                  >
                    {submitLoader ? "Please wait" : "Update"}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
