import { axiosAccountInstance } from "api/Request";

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";

import toast from "../../../../../components/Toastify";
import Tables from "views/examples/Tables";
import viewIcn from "../../../../../assets/img/icons/customIcons/view.svg";
import BookingModal from "components/Modals/BookingModal";
import { fetchUserData } from "helpers/storageData";
import approveIcn from "../../../../../assets/img/icons/customIcons/approve.svg";
import rejectIcn from "../../../../../assets/img/icons/customIcons/reject.svg";
import { fetchExpiringRequests } from "redux/actions/admin/ExpiringRequestAction";
import SOWUploadModal from "components/Modals/SOWUploadModal";
import { getCompanyBookingPendingInfo } from "redux/actions/admin/BookingPendingRequestAction";
import { updatePendingCount } from "redux/actions/admin/PendingCountRequestAction";
import { getAllBookingRequestInfo } from "redux/actions/admin/AllBookingRequestAction";

function CompanyPendingRequestcard({ loader }) {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [rejectModal,setRejectModal]=useState(false)
  const [data, setData] = useState([]);
  const [employeeID, setEmployeeID] = useState("");
  const [errors, setErrors] = useState(null);
  const [rejectRequestReason, setRejectRequestReason] = useState("");
  const [bookingData, setBookingData] = useState({});
  const [bookingModal, setBookingModal] = useState(false);

  const pendingCompnayRequestList = useSelector(
    (state) => state.BookingPendingReducer.result
  );

  const [sowHistory, setSOWHistory] = useState([]);
  const [sowData, setSOWData] = useState([]);
  const [approveFormData, setApproveFormData] = useState({
    _id: null,
    sow_status: null,
    sowDocument: null,
  });

  const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(true); //multiple click disabled
  const [sowModal, setSOWModal] = useState(false);

  const close = () => {
    setRejectModal(false)
    setRejectRequestReason("");
    setSOWModal(false);
    setModal(false);
    setBookingModal(false);
    setErrors({ ...errors, sowDocument: null,rejectRequestReason:null });
  };

  const interviewRejectHandler= (dataObj) => {
    setBookingData(dataObj);
    setRejectModal(true)

  };
  const imageSelect = (image) => {
    if (image) {
      setIsRejectButtonDisabled(false);
    }

    const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB maximum size limit
    if (image.size > MAX_IMAGE_SIZE) {
      setErrors({
        ...errors,
        sowDocument: `File size exceeds the maximum limit of ${
          MAX_IMAGE_SIZE / (1024 * 1024)
        }MB`,
      });
      setApproveFormData({
        ...approveFormData,
        sowDocument: null,
      });
      setIsApproveButtonDisabled(true);
    } else {
      setErrors({ ...errors, sowDocument: null });
      setApproveFormData({
        ...approveFormData,
        sowDocument: image,
      });
      setIsApproveButtonDisabled(false);
    }
    // setErrors({ ...errors, sowDocument: null });
    // setApproveFormData({
    //   ...approveFormData,
    //   sowDocument: image,
    // });
    // setIsApproveButtonDisabled(false);
  };

  const onApproveSOW = async () => {
    setIsApproveButtonDisabled(true);
    const finalData = approveFormData;
    if (!finalData?.sowDocument) {
      setErrors({ ...errors, sowDocument: "SOW document required" });
    } else {
      const data = new FormData();
      data.append("_id", sowData?._id);
      data.append("sowDocument", finalData?.sowDocument);
      data.append("sow_status", sowData?.sow_status);
      submitRequestSOW(data, "approved");
    }
  };

  const sowRequestHandler = (id, data) => {
    setIsApproveButtonDisabled(false);
    console.log("sow data  ", data);
    setSOWModal(true);
    setSOWData(data);
    setEmployeeID(id);
    setSOWHistory(data?.activity?.filter((obj) => obj.action.includes("SOW")));
  };

  <SOWUploadModal
    sowModal={sowModal}
    close={close}
    onApproveSOW={onApproveSOW}
    sowHistory={sowHistory}
    isApproveButtonDisabled={isApproveButtonDisabled}
    errors={errors}
    imageSelect={imageSelect}
  />;

  useEffect(() => {
    setData(pendingCompnayRequestList?.booking || []);
  }, [pendingCompnayRequestList]);

  const handleOnChange = (e) => {
    setRejectRequestReason(e.target.value);
    if (e.target.value) {
      setIsRejectButtonDisabled(false);
    }
    setErrors({ ...errors, rejectRequestReason: null });
  };

  const rejectHandler = (id) => {
    setEmployeeID(id);
    setModal(!modal);
  };
  const onRejectInterview = () => {
    setIsRejectButtonDisabled(true);
    const finalData = {
      employeeId: bookingData.employeeId,
      bookingId:bookingData.bookingId,
      interviewRejectReason:rejectRequestReason,
    };
    rejectRequest(finalData);
  };

  const rejectRequest = (data) => {
   
    const isValid = validation(data);
    if(isValid){
      axiosAccountInstance
        .post("/reject_interview", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            debugger
              socketInstance.emit("message", {
                type: "UPDATE_REJECT_INTERVIEW",
                // subType: "UPDATE_APPROVED_BOOKING_LIST",
                key: data?.bookingId,
                extraInfo: fetchUserData()?.companyName,
              });
              toast.success(res.data.body.message);
            
            dispatch(getAllBookingRequestInfo("0", "1", null, ""));
           

            setRejectModal(false);
            setRejectRequestReason("");
           
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
        
    }
  
};
  const validation = (data) => {
    const errorsFound = {};
    console.log(rejectRequestReason);

    if (!rejectRequestReason || rejectRequestReason === "") {
      errorsFound["rejectRequestReason"] = "Reject Request Reason is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };

  const socketInstance = useSelector((state) => state.socketReducer.result);

  const setCompanyApproval = (isValid = true, data, requesttype) => {
    if (isValid) {
      axiosAccountInstance
        .post("/booking/request/approve", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            if (requesttype === "approved") {
              socketInstance.emit("message", {
                type: "UPDATE_BOOKING",
                subType: "UPDATE_APPROVED_BOOKING_LIST",
                key: data?._id,
                extraInfo: fetchUserData()?.companyName,
              });
              toast.success(res.data.body.message);
            } else {
              socketInstance.emit("message", {
                type: "UPDATE_BOOKING",
                subType: "UPDATE_REJECTED_BOOKING_LIST",
                key: data?._id,
                extraInfo: fetchUserData()?.companyName,
              });
              toast.success(res.data.body.message);
            }
            dispatch(getCompanyBookingPendingInfo("0", "1", null, ""));
            // dispatch(setCompanyBookingApprovalInfo("0", "1", "pending"));

            setModal(false);
            setSOWModal(false);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setRejectRequestReason("");
    }
  };
  const totalPendingCount = useSelector(
    (state) => state.PendingCountRequestReducer.result
  );

  const setCompanyApprovalSOW = (isValid = true, data, requesttype) => {
    if (isValid) {
      axiosAccountInstance
        .post("/booking/sow_request/approve", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            if (requesttype === "approved") {
              socketInstance.emit("message", {
                type: "UPDATE_BOOKING",
                subType: "UPDATE_SOW_APPROVED_REQUEST_LIST",
                key: data.get("_id"),
                extraInfo: fetchUserData()?.companyName,
              });
              toast.success(res.data.body.message);
            } else {
              socketInstance.emit("message", {
                type: "UPDATE_BOOKING",
                subType: "UPDATE_SOW_REJECTED_REQUEST_LIST",
                key: data?._id,
                extraInfo: fetchUserData()?.companyName,
              });
              toast.success(res.data.body.message);
            }
            dispatch(getCompanyBookingPendingInfo("0", "1", null, ""));
            // dispatch(setCompanyBookingApprovalInfo("0", "1", "pending", null));
            const currentCount=totalPendingCount?.inbound_bookings?.length > 0 ? (totalPendingCount?.inbound_bookings[0]?.pending_requests)-1 : 0
            
            dispatch(updatePendingCount("INBOUND_PENDING_LIST_COUNT_UPDATE",currentCount));
            setModal(false);
            setSOWModal(false);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setRejectRequestReason("");
    }
  };
  const submitRequest = (data, str) => {
    if (data.status === "rejected") {
      const isValid = validation(data);
      setCompanyApproval(isValid, data);
    } else {
      setCompanyApproval(true, data, str);
    }
  };
  const submitRequestSOW = (data, str) => {
    if (data.status === "rejected") {
      const isValid = validation(data);
      setCompanyApprovalSOW(isValid, data);
    } else {
      setCompanyApprovalSOW(true, data, str);
    }
  };

  const onReject = () => {
    setIsRejectButtonDisabled(true);
    const finalData = {
      _id: employeeID,
      status: "rejected",
      rejectReason: rejectRequestReason,
    };
    submitRequest(finalData);
  };

  const [isRejectButtonDisabled, setIsRejectButtonDisabled] = useState(false); //multiple click disabled
  //  console.log("isRejectButtonDisabled",isRejectButtonDisabled)
  const onApprove = (_id) => {
    // setIsApproveButtonDisabled(true);
    const finalData = {
      _id,
      status: "approved",
    };
    submitRequest(finalData, "approved");

    setModal(false);
    dispatch(fetchExpiringRequests());
  };
  const viewHandler = (dataObj) => {
    setBookingData(dataObj);
    setBookingModal(true);
  };

  // const {
  //   bookedCompany,
  //   contactNumber,
  //   address,
  //   email,
  //   duration,
  //   endDate,
  //   time,
  // } = item;

  const colums = [
    { columnName: "Employee Name", columnTag: "name", type: "data" },

    { columnName: "Company", columnTag: "bookedCompany", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Date Range", columnTag: "duration", type: "objectData" },
    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },

    {
      columnName: "SOW",
      columnTag: "sow_final_status",
      type: "data",
      actionSOWApprove: sowRequestHandler,
    },

    { columnName: "Status", columnTag: "status", type: "data" },
    { columnName: "", columnTag: "", type: " " },
  ];
  const actionButtons = [
    {
      columnName: "",
      btnTitle: "View",
      type: "actionButton",
      btn: "ViewBooking",
      btnType: "primary",
      btnIcon: viewIcn,
      btnTooltip: "View",
      actionFunction: viewHandler,
    },
    {
      columnName: "",
      type: "actionButton",
      btnTitle: "Approve",
      btnType: "primary",
      btnIcon: approveIcn,
      btn: "PendingBookingApproval",
      actionFunction: onApprove,
      btnTooltip: "Approve",
      loading: loading,
      // disabled: isApproveButtonDisabled
    },
    {
      columnName: "",
      btnTitle: "Reject",
      btnTooltip: "Reject",
      type: "actionButton",
      btnIcon: rejectIcn,
      btn: "PendingBookingReject",
      btnType: "danger",
      actionFunction: rejectHandler,
    },
    {
      columnName: "",
      btnTitle: "Reject",
      btnTooltip: "Reject",
      type: "actionButton",
      btnIcon: rejectIcn,
      btn: "PendingInterviewReject",
      btnType: "danger",
      actionFunction: interviewRejectHandler,
    },
  ];
  return (
    <div>
      {data && (
        <Tables
          isAllBooking={true}
          coloums={colums}
          data={pendingCompnayRequestList?.booking}
          actionButtons={actionButtons}
          loader={loader}
        />
      )}
      {/* <Row className="justify-content-center mt-1">
            <Col md="12">
              <Card className="card-stats  my-1">
                <CardBody>
                  <Row>
                   
                    <div className="col-lg-3 col-sm-4">
                      <div className="card-box">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase font-weight-bolder text-darker mb-1"
                        >
                          Company Requested
                        </CardTitle>
                        <p className="h5 font-weight-normal mb-0">
                          {item?.bookedCompany}
                        </p>
                       
                      </div>
                    </div>
                   
                    <div className="col-lg-3 col-sm-4">
                      <div className="card-box">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase font-weight-bolder text-darker mb-1"
                        >
                          Duration
                        </CardTitle>

                        <p className="h5 font-weight-normal mb-0">
                          <span className="font-weight-bold">From Date :</span>{" "}
                          {fromDate}
                        </p>
                        <p className="h5 font-weight-normal mb-0">
                          <span className="font-weight-bold">To Date :</span>{" "}
                          {toDate}
                        </p>
                        <p className="h5 font-weight-normal mb-0">
                          <span className="font-weight-bold">
                            Working days :
                          </span>{" "}
                          {item?.workingDays}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-4">
                      <div className="card-box">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase font-weight-bolder text-darker mb-1"
                        >
                          Approved Date
                        </CardTitle>

                        <p className="h5 font-weight-normal mb-0">
                          {approvedDate}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-sm-12">
                      <div className="card-box button-boxes justify-content-lg-end">
                        <div className="text-center mr-2">
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() => onApprove(item._id)}
                            outline
                          >
                            Approve
                          </Button>
                        </div>

                        <div className="text-center mr-2">
                          <Button
                            size="sm"
                            color="danger"
                            outline
                            onClick={() => rejectHandler(item._id)}
                          >
                            Reject
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
      <Modal toggle={close} centered isOpen={modal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              Reject Booking
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal">
            Do you want to reject the booking request?
          </label>
          <FormGroup row>
            <Label for="exampleText" sm={12}>
              Rejection Remarks
            </Label>
            <Col sm={12}>
              <Input
                id="exampleText"
                name="text"
                type="textarea"
                onChange={handleOnChange}
                value={rejectRequestReason}
                invalid={!!errors && !!errors.rejectRequestReason}
              />
              <FormFeedback
                className={errors?.rejectRequestReason && "invalid-field"}
              >
                {errors?.rejectRequestReason}
              </FormFeedback>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            onClick={() => onReject()}
            color="primary"
            disabled={isRejectButtonDisabled}
          >
            Submit
          </Button>{" "}
          {/* <Button size="sm" onClick={close}>
              Cancel
            </Button> */}
        </ModalFooter>
      </Modal>
      <Modal toggle={close} centered isOpen={rejectModal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
            REJECT INTERVIEW REQUEST
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal">
          Do you want to reject the interview?
          </label>
          <FormGroup row>
            <Label for="exampleText" sm={12}>
              Reason
              <span style={{ color: "red" }}>*</span>
            </Label>
            <Col sm={12}>
              <Input
                id="exampleText"
                name="text"
                type="textarea"
                onChange={handleOnChange}
                value={rejectRequestReason}
                invalid={!!errors && !!errors.rejectRequestReason}
              />
              <FormFeedback
                className={errors?.rejectRequestReason && "invalid-field"}
              >
                {errors?.rejectRequestReason}
              </FormFeedback>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            onClick={() => onRejectInterview()}
            color="primary"
            disabled={isRejectButtonDisabled}
          >
            Submit
          </Button>{" "}
          
        </ModalFooter>
      </Modal>
      <SOWUploadModal
        sowModal={sowModal}
        close={close}
        onApproveSOW={onApproveSOW}
        sowHistory={sowHistory}
        isApproveButtonDisabled={isApproveButtonDisabled}
        errors={errors}
        imageSelect={imageSelect}
        sowData={sowData}
      />
      <BookingModal modal={bookingModal} close={close} data={bookingData} />
    </div>
  );
}

export default CompanyPendingRequestcard;
