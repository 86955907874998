import { axiosAccountInstance } from "api/Request";

import { formatDate } from "helpers/getDate";
import history from "helpers/History";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import viewIcn from "../../../../assets/img/icons/customIcons/view.svg";
import toast from "../../../../components/Toastify";
// import { ReactSearchAutocomplete } from "react-search-autocomplete";
import SOWUploadModal from "components/Modals/SOWUploadModal";
import Tables from "views/examples/Tables";
import EmployeeModal from "components/Modals/EmployeeModal";
import { getResumeRequests } from "redux/actions/admin/ResumeRequestsAction";
import { fetchUserData } from "helpers/storageData";

function InboundResumeRequestCard({
  searchFormData,
  tab,
  loader,
  // isEmployeeUnavailable,
}) {
  const dispatch = useDispatch();
 
  // useEffect(() => {
  //   if(tab==="")
  //   dispatch(getResumeRequests("0","1",""));
  // }, []); 
  const close = () => {
    setEmpModal(false);
    setSOWModal(false);
  };
  const [approveFormData, setApproveFormData] = useState({
    employeeId: null,
    resume: null,
  });
  const [errors, setErrors] = useState(null);
  const [employeeID, setEmployeeID] = useState("");
  const [data, setData] = useState([]);
  const [sowModal, setSOWModal] = useState(false);
  const [sowData, setSOWData] = useState([]);
  const [empModal, setEmpModal] = useState(false);
  const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(true);
  const [employeeData, setEmployeeData] = useState({});
  const [currentBooking, setCurrentBooking] = useState([]);

  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   dispatch(getResumeRequests("0","",""));
  // }, [dispatch]);
  const socketInstance = useSelector((state) => state.socketReducer.result);
  
  const fetchBookingEmployeeListResult = useSelector(
    (state) => state.ResumeRequestReducer.result
  );

  useEffect(() => {
    

    if (fetchBookingEmployeeListResult) {
      console.log(
        "fetchBookingEmployeeListResult",
        fetchBookingEmployeeListResult?.requests
      );
      setData(fetchBookingEmployeeListResult.requests);
    }
    return () => {
      setData([]);
    };
  }, [fetchBookingEmployeeListResult]);
  const sowRequestHandler = (id, data) => {
    setIsApproveButtonDisabled(false);
    console.log("sow data  ", data);
    setSOWModal(true);
    setSOWData(data);
    setEmployeeID(data.employeeId);

// console.log("sowData",sowData)
    // setSOWHistory(data?.activity?.filter((obj) => obj.action.includes("SOW")));
  };
  console.log("sowData",sowData)

  const viewHandler = async (dataObj) => {
    setLoading(true);
    setEmployeeData(dataObj);
    setEmpModal(true);
    console.log("emp/...........................", dataObj);
    await axiosAccountInstance
      .get(`/employee_current_bookings?employeeId=${dataObj?.employeeId}`)
      .then((res) => {
        const currentData = res.data.body.data.bookingHistory;
        setCurrentBooking(currentData);
      });

    setLoading(false);
  };

  const colums = [
    { columnName: "Employee Name", columnTag: "name", type: "data" },

    { columnName: "Company", columnTag: "requiredCompany", type: "data" },
    { columnName: " Date", columnTag: "createdAt", type: "data" },
    {
      columnName: "Experience",
      columnTag: "experience",
      type: "text",
    },

    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Skills", columnTag: "skillSets", type: "mapping" },

    {
      columnName: "Resume",
      columnTag: "resumeStatus",
      type: "data",
      actionResumeUpload: sowRequestHandler,
    },

    { columnName: "", columnTag: "", type: "" },
  ];
  
  const actionButtons = [
    {
      columnName: "",
      btnTitle: "View",
      btnIcon: viewIcn,
      btnTooltip: "View",
      type: "actionButton",
      btn: "ViewEmployee",
      btnType: "primary",
      actionFunction: viewHandler,
    },
   
  ];
  const imageSelect = (image) => {
    debugger
    console.log("img",image)
    

    const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB maximum size limit
    if (image.size > MAX_IMAGE_SIZE) {
      console.log("inif...")
      setErrors({
        ...errors,
        resume: `File size exceeds the maximum limit of ${
          MAX_IMAGE_SIZE / (1024 * 1024)
        }MB`,
      });
      setApproveFormData({
        ...approveFormData,
        resume: null,
      });
      setIsApproveButtonDisabled(true);
    } else {
      console.log("inelse")
      setErrors({ ...errors, resume: null });
      setApproveFormData({
        ...approveFormData,
        resume: image,
      });
      setIsApproveButtonDisabled(false);
    }
    // setErrors({ ...errors, sowDocument: null });
    // setApproveFormData({
    //   ...approveFormData,
    //   sowDocument: image,
    // });
    // setIsApproveButtonDisabled(false);
  };

  const submitRequestSOW = (data) => {
    debugger;
    axiosAccountInstance
      .post("/resume-upload", data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          socketInstance.emit("message", {
            type: "RESUME UPLOADED",
            key: res.data.body.data?.empId,
            user: fetchUserData()?.companyId,
            extraInfo: fetchUserData()?.companyName,
          });

          toast.success(res.data.body.message);

          dispatch(getResumeRequests("0", "1", ""));

          setSOWModal(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.body.message);
      });
  };

  const onResumeUpload = async (e) => {
    setIsApproveButtonDisabled(true);
    
    const finalData = approveFormData;
   
    if (!finalData?.resume) {
      setErrors({ ...errors, resume: "Resume is required" });
    } else {
      const newdata = new FormData();
      console.log("idd/........./",employeeID)
      newdata.append("employeeId", employeeID);
      newdata.append("resume", finalData?.resume);
      console.log("my fnl Dataaaaaaaa ", newdata);
      submitRequestSOW(newdata);
    }
  };

  return (
    <div>
      <Tables
        isInbound={true}
        coloums={colums}
        data={fetchBookingEmployeeListResult?.requests}
        actionButtons={actionButtons}
        loader={loader}
        // isBookEmployee={true}
        // isEmployeeUnavailable={isEmployeeUnavailable}
      />

      <SOWUploadModal
        isInbound={true}
        sowModal={sowModal}
        close={close}
        onApproveSOW={onResumeUpload}
        isApproveButtonDisabled={isApproveButtonDisabled}
        errors={errors}
        imageSelect={imageSelect}
        sowData={sowData}
      />

      <EmployeeModal
        isInbound={true}
        isOutbound={false}
        modal={empModal}
        close={close}
        data={employeeData}
        currentData={currentBooking}
        loading={loading}
        resumeUploadHandler={sowRequestHandler}
      />
    </div>
  );
}

export default InboundResumeRequestCard;
