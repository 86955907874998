import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import history from "helpers/History";
import viewIcn from "../../../../assets/img/icons/customIcons/view.svg";
import "react-toastify/dist/ReactToastify.css";
import { axiosAccountInstance } from "api/Request";
import toast from "../../../../components/Toastify";
import Tables from "views/examples/Tables";
import { setEmployeeDeleteInfo } from "redux/actions/super-admin/EmployeeListAction";
import { setEmployeeListInfo } from "redux/actions/super-admin/EmployeeListAction";
import editIcn from "../../../../assets/img/icons/customIcons/edit.svg";
import deleteIcn from "../../../../assets/img/icons/customIcons/delete.svg";
import bookhistoryIcn from "../../../../assets/img/icons/customIcons/booking-history.svg";
import BookingModal from "components/Modals/BookingModal";

function EmployeeCard({ searchFormData, companyIdVal, loader }) {
  const dispatch = useDispatch();

  const [bookingStatus, setStatus] = useState("");
  const [, setData] = useState([]);
  const [employeeData, setEmployeeData] = useState({});
  const [modal, setModal] = useState(false);
  const [employeeViewModal, setEmployeeViewModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [actionButtons, setActionButtons] = useState([]);
  const viewEmployeeHandler = async (dataObj) => {
    setEmployeeViewModal(true);
    setEmployeeData(dataObj);
  };
  useEffect(() => {
    if (companyIdVal) {
      setActionButtons([
        {
          columnName: "",
          btnTitle: "View",
          btnTooltip: "View",
          type: "actionButton",
          btn: "ViewEmployee",
          btnType: "primary",
          btnIcon: viewIcn,
          actionFunction: viewEmployeeHandler,
        },
        // {
        //   columnName: "",
        //   type: "actionButton",
        //   btnTitle: "Booking History",
        //   btnType: "primary",
        //   btnTooltip: "Booking History",
        //   toolTip: "Booking History",
        //   btnIcon: bookhistoryIcn,

        //   btn: "EmployeeBookingHistory",
        //   actionFunction: viewBookingHandler,
        // },

        // {
        //   columnName: "",
        //   btnTitle: "Delete",
        //   type: "actionButton",
        //   btn: "EmployeeDelete",
        //   btnIcon: deleteIcn,
        //   btnTooltip: "Delete",
        //   toolTip: "Delete",
        //   btnType: "danger",
        //   actionFunction: deleteEmployee,
        // },
      ]);
    } else {
      setActionButtons([
        {
          columnName: "",
          btnTitle: "View",
          btnTooltip: "View",
          type: "actionButton",
          btn: "ViewEmployee",
          btnType: "primary",
          btnIcon: viewIcn,
          actionFunction: viewEmployeeHandler,
        },
        {
          columnName: "",
          type: "actionButton",
          btnTitle: "Booking History",
          btnTooltip: "Booking History",
          btnIcon: bookhistoryIcn,
          btnType: "primary",
          icon: <i className="fa fa-eye" aria-hidden="true"></i>,
          toolTip: "Booking History",
          btn: "EmployeeBookingHistory",
          actionFunction: viewBookingHandler,
        },
        {
          columnName: "",
          type: "actionButton",
          btnTitle: "Edit",
          btnTooltip: "Edit",
          btnIcon: editIcn,
          icon: <i className="fa fa-eraser" aria-hidden="true"></i>,
          toolTip: "Edit",
          btnType: "primary",
          btn: "EmployeeEdit",
          actionFunction: viewEditEmployeeHandler,
        },
        {
          columnName: "",
          btnTitle: "Delete",
          type: "actionButton",
          btnTooltip: "Delete",
          btnIcon: deleteIcn,
          btn: "EmployeeDelete",
          icon: <i className="fa fa-trash" aria-hidden="true"></i>,
          toolTip: "Delete",
          btnType: "danger",
          actionFunction: deleteEmployee,
        },
      ]);
    }
    return () => {
      setActionButtons([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEmployeeListResult = useSelector(
    (state) => state.EmployeeListReducer.employeeList.result
  );
  const close = () => {
    setModal(false);
  };

  const closeEmployeeModal = () => {
    setEmployeeViewModal(false);
  };

  useEffect(() => {
    console.log("fetchEmployeeListResult", fetchEmployeeListResult);
    if (fetchEmployeeListResult) {
      setData(fetchEmployeeListResult.employees);
    }
    return () => {
      setData([]);
    };
  }, [fetchEmployeeListResult]);

  const deleteEmployee = (id, item) => {
    setStatus(item.booking_status);
    setSelectedEmployee(id);
    setModal(!modal);
  };

  const editEmployeeStatus = (data) => {
    axiosAccountInstance
      .post("/employee/status", data)
      .then((res) => {
        if (data?.status === "inactive") {
          toast.success("Employee Deactivated");
        } else {
          toast.success("Employee Activated");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.body.message);
        dispatch(setEmployeeListInfo("listing"));
      });
  };

  const submit = () => {
    dispatch(setEmployeeDeleteInfo(selectedEmployee));
    setModal(!modal);
  };

  const viewBookingHandler = (item) => {
    history.push({pathname:`/admin/booking-history/${item.employeeId}`,state:item});
   
  };
  const viewEditEmployeeHandler = (id) => {
    // history.push(`/admin/edit-employees/${id}`);
    history.push({
      pathname: `/admin/edit-employees/${id}`,
      state: {
        type: "single_upload",
      },
    });
  };
  const colums = [
    { columnName: "Employee Name", columnTag: "name", type: "data" },
    // { columnName: "Employee Id", columnTag: "empId", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Created", columnTag: "createdAt", type: "data" },
    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },
    // { columnName: "Currency", columnTag: "currencyCode", type: "data" },

    {
      columnName: "Availability",
      type: "toggle",
      btnTitle: "Status",
      btnType: "primary",
      btn: "EmployeeStatus",
      actionFunction: editEmployeeStatus,
    },
    { columnName: "", columnTag: "", type: "" },
  ];

  return (
    <div>
      <Tables
        coloums={colums}
        data={fetchEmployeeListResult?.employees}
        actionButtons={actionButtons}
        loader={loader}
        isEmployeeStatus={true}
      />

      <Modal toggle={close} size="sm" centered isOpen={modal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              Delete employee
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal mb-0">
            {bookingStatus === "Booked" || bookingStatus === "Booked Ahead" //for current and future bookings
              ? "Employee cannot be deleted due to prior booking. "
              : "Do you want to delete the employee?"}
          </label>
        </ModalBody>
        <ModalFooter>
          {bookingStatus === "Booked" || bookingStatus === "Booked Ahead" ? (
            <Button size="sm" onClick={close} color="primary">
              Cancel
            </Button>
          ) : (
            <Button size="sm" onClick={() => submit()} color="danger">
              Yes
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <BookingModal
        modal={employeeViewModal}
        close={closeEmployeeModal}
        data={employeeData}
        isEmployee={true}
      />
    </div>
  );
}

export default EmployeeCard;
