import React, { useEffect, useState } from "react";
import EmployeeSearchCard from "./EmployeeSearchCard";
import { Row, Container, Col,Button } from "reactstrap";
import docIcon from "../../../../assets/img/icons/customIcons/document.svg"
import resumeIcon from "../../../../assets/img/icons/customIcons/resume2.png"
import { useDispatch, useSelector } from "react-redux";
import { setEmployeeListInfo } from "redux/actions/super-admin/EmployeeListAction";
import { formatDate } from "helpers/getDate";
import PaginationComponent from "components/pagination/Pagination";
import DashboardHeader from "components/Headers/DashboardHeader";
import SearchIcon from "../../../../assets/img/icons/customIcons/searchicondark.svg";
import CustomFilter from "components/customFilter";
import moment from "moment";
import history from "helpers/History";

function EmployeeSearchList(state) {
  const dispatch = useDispatch();
  const [tableHeight, setTableHeigth] = useState(100);
  const [initFromDate, setInitFromDate] = useState("");
  const [initToDate, setInitToDate] = useState("");
  const [tab, setTab] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [empIdChip, setEmpIdChip] = useState(false);

  const [searchFormData, setSearchFormData] = useState({
    experience: null,
    skillSet: null,
    fromDate: null,
    toDate: null,
    search: state.location.state?.empId ? state.location.state?.empId : null,
    page: null,
    limit: null,
    companyName: null,
  });
  const [empId, setEmpId] = useState(
    state.location.state?.empId ? state.location.state?.empId : null
  );
  const fetchBookingEmployeeListResult = useSelector(
    (state) => state.EmployeeListReducer.bookingList.result
  );
  const fetchBookingEmployeeListLoader = useSelector(
    (state) => state.EmployeeListReducer.bookingList.loader
  );
  const notification = useSelector((state) => state.NotificationReducer.result);

  useEffect(() => {
    setInitFromDate(formatDate());
    setInitToDate(formatDate(1));
    setSearchFormData({
      ...searchFormData,
      fromDate: formatDate(),
      toDate: formatDate(1),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagination = (tab) => {
    setTab(tab);
    // setSearchFormData({
    //   ...searchFormData,
    //   page: tab,
    // });
    const finalData = {
      ...searchFormData,
      page: tab,
      // fromDate: initFromDate,
      // toDate: initToDate,
    };

    employeeListing("booking", finalData);
  };

  const handleEmployeeSearchOnChange = (e, v) => {
    setSearchFormData({ ...searchFormData, [e]: v });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    employeeListing("booking", searchFormData);
  };

  const handleSearchOnSelect = (value, name) => {
    let data =
      name === "experience"
        ? { ...searchFormData, experience: value }
        : name === "date"
        ? {
            ...searchFormData,
            fromDate: moment(value.startDate).format("YYYY-MM-DD"),
            toDate: moment(value.endDate).format("YYYY-MM-DD"),
          }
        : name === "companyName"
        ? { ...searchFormData, companyName: value }
        : { ...searchFormData, search: value };
    // let data = { ...searchFormData, search: value };
    setSearchFormData(data);
    employeeListing("booking", data);
  };

  const employeeListing = (type, searchFormData) => {
    dispatch(setEmployeeListInfo(type, searchFormData));
  };

  const handleClearDateBookEmployee = () => {
    setSearchFormData({
      ...searchFormData,
      fromDate: formatDate(),
      toDate: formatDate(1),
    });
    employeeListing("booking", searchFormData);
  };

  useEffect(() => {
    const finalData = {
      ...searchFormData,
      fromDate: initFromDate,
      toDate: initToDate,
    };

    if (initFromDate && initToDate) {
      employeeListing("booking", finalData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initToDate, initFromDate]);

  // eslint-disable-next-line
  useEffect(() => {
    const divElement = document.getElementById("tableDiv");

    if (fetchBookingEmployeeListResult?.pages?.length > 1) {
      setTableHeigth(divElement.scrollHeight - 72 + "px");
    } else {
      setTableHeigth(divElement.scrollHeight - 12 + "px");
    }
  });

  useEffect(() => {
    if (empId) {
      setEmpIdChip(true);
      employeeListing("booking", {
        ...searchFormData,
        search: empId,
        fromDate: formatDate(),
        toDate: formatDate(1),
      });
    } else if (notification?.empId) {   //for ntf redirection no date filter
      setEmpIdChip(true);
      employeeListing("booking", {
        // ...searchFormData,
        search: notification?.empId,
        // fromDate: formatDate(),
        // toDate: formatDate(1),
      });
    } else {
      setEmpIdChip(false);
      // employeeListing("booking", { ...searchFormData, search: null });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const clearChip = () => {
    setEmpIdChip(false);

    employeeListing("booking", { ...searchFormData, search: null });

    setEmpId("");
    history.push("/admin/booking-employee");
    dispatch({
      type: "NOTIFICATION_RESET",
    });
  };
  const listResumeRequests = () => {
    history.push("/admin/resumes")
  };
  return (
    <>
      <DashboardHeader />
      <Container className="mt-4 main-contentarea container-fluid" fluid>
        <Row>
          <Col id="tableDiv" md={isFilter ? "9" : "12"}>
            <div className="d-flex">
              <Col md="10" className="d-flex">
                <h3 class="tab-title">Book Employees</h3>
                {empIdChip && (
                  <div class="chip ml-3">
                    {empId || notification?.empId}
                    <span onClick={clearChip} class="closebtn">
                      &times;
                    </span>
                  </div>
                )}
              </Col>
              <Col md={"2"}>
              {/* <button
                style={{whiteSpace:"nowrap"}}
                      type="button"
                      onClick={listResumeRequests}
                      className={"btn  mr-3 add-button-common border-0"}
                    >
                      Resume Requests
                    
                    </button> */}
                 <div className="d-flex "
                      style={{ justifyContent: "flex-end" }}>
                 <Button
                 style={{whiteSpace:"nowrap"}}
                        outline
                        className="mb-2 add-button-common border-0"
                        type="button"
                        onClick={listResumeRequests}
                      >
                        {" "}
                        {/* <span className="add-button">+</span> */}
                        <img alt="" src={resumeIcon} width={"15px"} />  {" "}
                        Resume Requests
                      </Button>
                <div className="common-outer-filter">
                  <div className="btn-group new-cutom-filter ">
                    <button
                      type="button"
                      onClick={() => setIsFilter(!isFilter)}
                      className={"btn dropdown-toggle mr-3"}
                    >
                      <img alt="" src={SearchIcon} width={"18px"} />
                    </button>
                  </div>
                </div>
                 </div>
              </Col>
            </div>
            <EmployeeSearchCard
              isEmployeeUnavailable={
                fetchBookingEmployeeListResult?.employees?.length === 0 &&
                empIdChip
              }
              searchFormData={searchFormData}
              tab={tab}
              loader={fetchBookingEmployeeListLoader}
            />

            <div
              style={{
                display: fetchBookingEmployeeListLoader ? "none" : "contents",
              }}
            >
              <PaginationComponent
                totalCount={fetchBookingEmployeeListResult?.itemCount}
                handlePagination={handlePagination}
                activePage={fetchBookingEmployeeListResult?.activePage}
              />
            </div>
          </Col>
          {/* {isFilter && ( */}
          <Col
            md="3"
            style={{
              height: tableHeight,
              minHeight: "750px",
              display: isFilter ? "block" : "none",
            }}
          >
            <CustomFilter
              tableHeight={tableHeight}
              type="bookemployee"
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearch}
              handleSearchOnSelect={handleSearchOnSelect}
              isFilter={isFilter}
              setIsFilter={setIsFilter}
              handleClearDateBookEmployee={handleClearDateBookEmployee}
            />
          </Col>
          {/* )} */}
        </Row>
      </Container>
    </>
  );
}

export default EmployeeSearchList;
