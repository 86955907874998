import { axiosAccountInstance } from "api/Request";
import { getSearchUrl } from "helpers/utils";



export const getScheduledInterviews = (searchData) => (dispatch) => {
    
  let url = `/interview?`
  url = getSearchUrl(url,searchData);
  dispatch({
    type: "SCHEDULED_INTERVIEW_LIST_BEGINS",
  });

  axiosAccountInstance
    .get(url)
    .then((result) => {
      // dispatching success
      dispatch({
        type: "SCHEDULED_INTERVIEW_LIST_SUCCESS",
        payload: result.data.body.data,
      });
      // if (result.data.statusCode === 200) {
      //   return dispatch({
      //     type: "SCHEDULED_INTERVIEW_LIST_SUCCESS",
      //     payload: result.data.body.data,
      //   });
      // }
    })
    
    .catch((err) => {
      dispatch({
        type: "SCHEDULED_INTERVIEW_LIST_FAILURE",
        payload: "FALIURE",
      });
    });
};

