import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import viewIcn from "../../../../assets/img/icons/customIcons/view.svg";
import Tables from "views/examples/Tables";
import { toast } from "react-toastify";
import history from "helpers/History";
import { axiosAccountInstance } from "api/Request";
import { getScheduledInterviews } from "redux/actions/super-admin/ScheduledInterviewAction";
import BookingModal from "components/Modals/BookingModal";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Input,
  ModalFooter,
  FormFeedback
} from "reactstrap";

function ScheduledInterviewListCard({ loader }) {
  const dispatch = useDispatch();
  const [bookingData, setBookingData] = useState({});
  const [bookingModal, setBookingModal] = useState(false);
  const [addModal,setAddModal]=useState(false)
  const [linkData, setLinkData] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [errors,setErrors]=useState("")
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); 

  useEffect(() => {
    dispatch(getScheduledInterviews());
  }, [dispatch]);

  const scheduledInterviewListResult = useSelector(
    (state) => state.ScheduledInterviewReducer?.result
  );
  console.log("int",scheduledInterviewListResult);
  

  // Close modal
  const close = () => {
    setBookingModal(false);
    setAddModal(false)
    setLinkData("")
    setErrors("")
  };
  const viewHandler = (dataObj) => {
    setBookingData(dataObj);
    setBookingModal(true);
 
  };
  const validation = () => {
    const errorsFound = {};
    // console.log(rejectRequestReason);

    if (!linkData || linkData === "") {
      errorsFound["linkData"] = "Interview link is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const handleOnChange = (e) => {
    setLinkData(e.target.value);
    if (e.target.value) {
      setIsButtonDisabled(false);
    }
    setErrors({ ...errors, linkData: null });
  };
  const onSubmit= () => {
    const isValid = validation();
   if(isValid){
    const finalData = {
      
      bookingId:bookingId,
      interviewLink: linkData,
    };
   
    axiosAccountInstance
    .post("/interview_link", finalData)
    .then((res) => {
      if (res.data.statusCode === 200) {
        // socketInstance.emit("message", {
        //   type: "REJECT_CANDIDATE",
          
        //   key: data?.bookingId,
        //   extraInfo: fetchUserData()?.companyName,
        // });
        toast.success(res.data.body.message);
      
        dispatch(getScheduledInterviews());

        setAddModal(false);
      }
    })
    .catch((err) => {
      toast.error(err.response.data.body.message);
    });
   }
   
    // setInterviewRejectReason("");
  
};
  const interviewHandler = (id, data) => {
    setIsButtonDisabled(false);
    console.log("sow data .......... ", data);
    setAddModal(true);
    setLinkData(data.interviewLink || "");
    setBookingId(data.bookingId);
    
  };

  const colums = [
    { columnName: "Interview Requested By", columnTag: "bookedCompany", type: "data" },
    { columnName: "Interview Requested To", columnTag: "parentCompany", type: "data" },
    {
      columnName: "Interview Date",
      columnTag: "selectedSlot",
      type: "formatDateSlot",
    },
   
    {
      columnName: "Interview Slot",
      columnTag: "selectedSlot",
      type: "formatTimeSlot",
    },
    {
      columnName: "Interview Link",
      columnTag: "interviewLink",
      type: "data",
      actionAddLink: interviewHandler,
    },
    { columnName: "", columnTag: "", type: " " },
  ];

  const actionButtons = [
    {
      columnName: "",
      btnTitle: "View",
      type: "actionButton",
      btn: "ViewBooking",
      btnType: "primary",
      btnIcon: viewIcn,
      btnTooltip: "View",
      actionFunction: viewHandler,
    },
  ];
  
  return (
    <div>
      <Tables
        actions={null}
        coloums={colums}
        data={scheduledInterviewListResult.booking}
        actionButtons={actionButtons}
        loader={loader}
      />
      <BookingModal modal={bookingModal} close={close} data={bookingData} />
      <Modal toggle={close} centered isOpen={addModal}>
            <Row>
              <div className="col">
                <ModalHeader className="text-uppercase font-weight-bolder">
                  Add Interview Link
                </ModalHeader>
              </div>
              <Row>
                <Col>
                  <ModalHeader className="text-uppercase pt-3 pb-2">
                    <Button onClick={close} className="mx-1" close />
                  </ModalHeader>
                </Col>
              </Row>
            </Row>
            <ModalBody>
              {/* <label className="font-weight-normal">
                Do you want to cancel the interview?
              </label> */}
              <FormGroup row>
                <Label for="exampleText" sm={12}>
                  Link
                  <span style={{ color: "red" }}>*</span>
                </Label>
                <Col sm={12}>
                  <Input
                    id="exampleText"
                    name="text"
                    type="textarea"
                    onChange={handleOnChange}
                    value={linkData}
                    invalid={!!errors && !!errors.linkData}
                  />
                  <FormFeedback
                    className={errors?.linkData && "invalid-field"}
                  >
                    {errors?.linkData}
                  </FormFeedback>
                </Col>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                onClick={() => onSubmit()}
                color="primary"
                disabled={isButtonDisabled}
              >
                Send Mail
              </Button>{" "}
            </ModalFooter>
          </Modal>
    </div>
  );
}

export default ScheduledInterviewListCard;
