// reactstrap components
import { Card, Table } from "reactstrap";
import moment from "moment";
import React from "react";
import { uniqueDate } from "helpers/getDate";
import { capitilizeFirstAlphabet } from "helpers/stringManupulate";

const ExpiringRequestsTable = ({ data = [], columns, viewButton, loader }) => {
  const handleViewBooking = (item) => {
    viewButton.viewHandler(item);
  };

  const backgroundColor = (item) => {
    if (item.duration) {
      const nowDate = new Date();
      const toDate = new Date(item.duration.toDate);
      const dateDifference = toDate - nowDate;

      const fiveDays = 3600 * 24 * 5 * 1000;
      const tenDays = 3600 * 24 * 10 * 1000;

      if (dateDifference < 0) return;

      if (dateDifference < fiveDays) {
        return "#fcece8";
      }
      if (dateDifference > fiveDays && dateDifference < tenDays) {
        return "#f9fcd6";
      }
    }
  };

  const daysTillExpiry = (item) => {
    if (item.duration) {
      const nowDate = new Date();
      const toDate = new Date(item.duration.toDate);

      const dateDifference = toDate - nowDate;
      const days = Math.ceil(dateDifference / (3600 * 24 * 1000));
      return days;
    }
  };

  return (
    <>
      {/* Page content */}
      {/* Table */}
      <Card className="shadow mt-1 ">
        <Table className="table-flush" hover>
          <thead>
            <tr>
              {columns?.map((item, key) => (
                <th key={key}>{item.columnName}</th>
              ))}
            </tr>
          </thead>

          {!loader && data.length > 0 && (
            <>
              <tbody>
                {data.map((item, key) => (
                  <tr
                    onClick={() => handleViewBooking(item)}
                    key={key}
                    style={{
                      backgroundColor: backgroundColor(item),
                      cursor: "pointer",
                    }}
                  >
                    {columns.map((column, index) => (
                      <>
                        {column.type === "company" && (
                          <td key={column.columnTag}>
                            {capitilizeFirstAlphabet(item[column.columnTag])}
                          </td>
                        )}
                        {column.type === "date" && (
                          <td key={column.columnTag}>
                            {uniqueDate(item[column.columnTag].fromDate)}{" "}
                            -{" "}
                            {uniqueDate(item[column.columnTag].toDate)}
                          </td>
                        )}
                        {column.type === "text" && (
                          <td key={column.columnTag}>
                            {item[column.columnTag]}
                          </td>
                        )}
                        {column.columnTag === "daysTillExpiry" && (
                          <td>{daysTillExpiry(item)}</td>
                        )}
                      </>
                    ))}
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </Table>

        {loader && (
          <div className="table-loader">
            <img
              alt="..."
              src={
                require("../../../../../assets/img/icons/customIcons/loader.gif")
                  .default
              }
            />
          </div>
        )}

        {!loader && data.length === 0 && (
          <div className="nodata-table">
            <img
              alt="..."
              src={
                require("../../../../../assets/img/icons/customIcons/nodata.gif")
                  .default
              }
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default ExpiringRequestsTable;
