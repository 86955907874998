const EmployeeListReducer = (state = {
  employeeList: { loader: false, result: [], error: null },
  bookingList: { loader: false, result: [], error: null },
}, action) => {
    switch (action.type) {
      case "EMPLOYEE_LIST_BEGINS":
        return {
          ...state,
          employeeList: {
            loader: true,
            result: state?.employeeList.result,
            // result: { employees: [] },
            error: null,
          },
        };

      case "EMPLOYEE_LIST_SUCCESS":
        return {...state,employeeList:{ loader: false,
          result: action.payload,
          error: null,}
         
        };
      case "EMPLOYEE_LIST_FAILURE":
        return {...state,employeeList:{
          loader: false,
          result: {},
          error: action.payload,
        }
         
        };
      case "BOOKING_LIST_BEGINS":
        return {...state,bookingList:{
          loader: true,
          result: state?.bookingList.result,
          // result: { employees: [] },
          error: null,
        }
         
        };
      case "BOOKING_LIST_SUCCESS":
        return {...state,bookingList:{
          loader: false,
          result: action.payload,
          error: null,
        }
          
        };
      case "BOOKING_LIST_FAILURE":
        return {...state,bookingList:{
          loader: false,
          result: {},
          error: action.payload,
        }
         
        };
    
        case 'EMPLOYEE_DELETE_SUCCESS':{
          debugger
          let currentEmployeeList = {...state.employeeList.result}  
          let allEmployeeList = currentEmployeeList && currentEmployeeList.employees
          allEmployeeList = allEmployeeList && allEmployeeList.filter(item => item._id !== action.payload)
          currentEmployeeList = {...currentEmployeeList, employees:allEmployeeList}
          return {...state,
            employeeList:{loader: false,
            result: currentEmployeeList,
            error: null,}
              
            }
          }
       
      case "EMPLOYEE_LIST_RESET":
        return {...state,employeeList:{
          loader: false,
          result: {},
          error: null,
        }
         
        };
      default:
        return state;
    }
  };
  
  export default EmployeeListReducer;