import React, { useEffect, useState } from "react";

import { Row, Container, Col,Button } from "reactstrap";
// import docIcon from "../../../../assets/img/icons/customIcons/document.svg"

import { useDispatch, useSelector } from "react-redux";

import { formatDate } from "helpers/getDate";
import PaginationComponent from "components/pagination/Pagination";
import DashboardHeader from "components/Headers/DashboardHeader";
import SearchIcon from "../../../../assets/img/icons/customIcons/searchicondark.svg";
import CustomFilter from "components/customFilter";
import moment from "moment";
import history from "helpers/History";
import { getResumeRequests } from "redux/actions/admin/ResumeRequestsAction";
import OutboundResumeRequestCard from "./OutboundResumeRequestCard";

function OutboundResumeRequestList(state) {
  const dispatch = useDispatch();
  const [tableHeight, setTableHeigth] = useState(100);
//   const [initFromDate, setInitFromDate] = useState("");
//   const [initToDate, setInitToDate] = useState("");
  const [tab, setTab] = useState(1);
  const [isFilter, setIsFilter] = useState(false);
  const [empIdChip, setEmpIdChip] = useState(false);

  const [searchFormData, setSearchFormData] = useState({
    experience: null,
    skillSet: null,
    // fromDate: null,
    // toDate: null,
    // search: state.location.state?.empId ? state.location.state?.empId : null,
    page: null,
    limit: null,
    // companyName: null,
  });
  // const [empId, setEmpId] = useState(
  //   state.location.state?.empId ? state.location.state?.empId : null
  // );

  const fetchOutboundRequests = useSelector(
    (state) => state.ResumeRequestReducer.result
  );
  
  const fetchOutboundRequestLoader = useSelector(
    (state) => state.ResumeRequestReducer.loader
  );
  const notification = useSelector((state) => state.NotificationReducer.result);

  useEffect(() => {
    // setInitFromDate(formatDate());
    // setInitToDate(formatDate(1));
    setSearchFormData({
      ...searchFormData,
      // fromDate: formatDate(),
      // toDate: formatDate(1),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePagination = (tab) => {
    setTab(tab);
    // setSearchFormData({
    //   ...searchFormData,
    //   page: tab,
    // });
    const finalData = {
      ...searchFormData,
      page: tab,
      // fromDate: initFromDate,
      // toDate: initToDate,
    };

    getInboundResumeList("1","1", finalData);
  };

  const handleEmployeeSearchOnChange = (e, v) => {
    setSearchFormData({ ...searchFormData, [e]: v });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    getInboundResumeList("1","1", searchFormData);
  };

  const handleSearchOnSelect = (value, name) => {
    let data =
      name === "experience"
        ? { ...searchFormData, experience: value }
        :
        //  name === "date"
        // ? {
        //     ...searchFormData,
        //     fromDate: moment(value.startDate).format("YYYY-MM-DD"),
        //     toDate: moment(value.endDate).format("YYYY-MM-DD"),
        //   }
        // :
         name === "companyName"
        ? { ...searchFormData, companyName: value }
        : { ...searchFormData, search: value };
    // let data = { ...searchFormData, search: value };
    setSearchFormData(data);
    getInboundResumeList('1','1', data);
  };

  const getInboundResumeList = (createdBy, page, searchFormData) => {
    dispatch(getResumeRequests(createdBy, page, searchFormData));
  };

  const handleClearDateBookEmployee = () => {
    setSearchFormData({
      ...searchFormData,
      // fromDate: formatDate(),
      // toDate: formatDate(1),
    });
    getInboundResumeList('1','1', searchFormData);
   
  };

  // useEffect(() => {
  //   const finalData = {
  //     ...searchFormData,
  //     // fromDate: initFromDate,
  //     // toDate: initToDate,
  //   };

  //   if (initFromDate && initToDate) {
  //     employeeListing("booking", finalData);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [initToDate, initFromDate]);

  // eslint-disable-next-line
  useEffect(() => {
    const divElement = document.getElementById("tableDiv");

    if (fetchOutboundRequests?.pages?.length > 1) {
      setTableHeigth(divElement.scrollHeight - 72 + "px");
    } else {
      setTableHeigth(divElement.scrollHeight - 12 + "px");
    }
  });

  useEffect(() => {
    // if (empId) {
    //   setEmpIdChip(true);
    //   getInboundResumeList("0","1", {
    //     ...searchFormData,
    //     search: empId,
    //     // fromDate: formatDate(),
    //     // toDate: formatDate(1),
    //   });
    // }
    if (notification?.empId) {
      setEmpIdChip(true);
      getInboundResumeList("1","1", {
        ...searchFormData,
        search: notification?.empId,
        // fromDate: formatDate(),
        // toDate: formatDate(1),
      });
    } else {
      setEmpIdChip(false);
      // employeeListing("booking", { ...searchFormData, search: null });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const clearChip = () => {
    setEmpIdChip(false);

    getInboundResumeList("1","1", { ...searchFormData, search: null });

    // setEmpId("");
    history.push("/admin/booking-employee");
    dispatch({
      type: "NOTIFICATION_RESET",
    });
  };
 
  return (
    <>
      <DashboardHeader />
      <Container className="mt-4 main-contentarea container-fluid" fluid>
        <Row>
          <Col id="tableDiv" md={isFilter ? "9" : "12"}>
            <div className="d-flex">
              <Col md="10" className="d-flex">
                <h3 class="tab-title">Outbound Resume Requests</h3>
                {empIdChip && (
                  <div class="chip ml-3">
                    {notification?.empId}
                    <span onClick={clearChip} class="closebtn">
                      &times;
                    </span>
                  </div>
                )}
              </Col>
              <Col md="2">
                <div className="common-outer-filter">
                {/* <button
                      type="button"
                      onClick={listResumeRequests}
                      className={"btn  mr-3"}
                    >
                     
                    </button> */}
                  <div className="btn-group new-cutom-filter ">
                   
                    <button
                      type="button"
                      onClick={() => setIsFilter(!isFilter)}
                      className={"btn dropdown-toggle mr-3"}
                    >
                      <img alt="" src={SearchIcon} width={"18px"} />
                    </button>
                  </div>
                </div>
              </Col>
            </div>
            <OutboundResumeRequestCard
              // isEmployeeUnavailable={
              //   fetchOutboundRequests?.requests?.length === 0 &&
              //   empIdChip
              // }
              searchFormData={searchFormData}
              tab={tab}
              loader={fetchOutboundRequestLoader}
            />

            <div
              style={{
                display: fetchOutboundRequestLoader ? "none" : "contents",
              }}
            >
              <PaginationComponent
                totalCount={fetchOutboundRequests?.itemCount}
                handlePagination={handlePagination}
                activePage={fetchOutboundRequests?.activePage}
              />
            </div>
          </Col>
          {/* {isFilter && ( */}
          <Col
            md="3"
            style={{
              height: tableHeight,
              minHeight: "750px",
              display: isFilter ? "block" : "none",
            }}
          >
            <CustomFilter
              tableHeight={tableHeight}
              type="resume_requests"
              handleEmployeeSearchOnChange={handleEmployeeSearchOnChange}
              handleSearch={handleSearch}
              handleSearchOnSelect={handleSearchOnSelect}
              isFilter={isFilter}
              setIsFilter={setIsFilter}
              handleClearDateBookEmployee={handleClearDateBookEmployee}
            />
          </Col>
          {/* )} */}
        </Row>
      </Container>
    </>
  );
}

export default OutboundResumeRequestList;
