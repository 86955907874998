// import React, { useEffect, useState } from "react";
import ScheduledInterviewListCard from "./ScheduledInterviewListCard";
import { Row, Col, Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../../../components/pagination/Pagination"
import { getScheduledInterviews } from "redux/actions/super-admin/ScheduledInterviewAction";

function ScheduledInterviewList({
//   handleEmployeeSearchOnChange,
//   handleSearch,
  searchFormData,
//   setSearchFormData,
  data,
  fromSuperAdmin,
}) {
  const dispatch = useDispatch();
  const handlePagination = (tab) => {
    debugger

    const finalData = {
      ...searchFormData,
      page: tab,
    };

    dispatch( getScheduledInterviews(finalData))
  };
  const scheduledInterviewListLoader = useSelector(
    (state) => state.ScheduledInterviewReducer?.loader)
    const scheduledInterviewListResult = useSelector(
      (state) => state.ScheduledInterviewReducer?.result
    );
    console.log("Total Count:", scheduledInterviewListResult.itemCount);
  return(
    <>
     <Container className="mt-2 main-contentarea" fluid>
          <Row>
            {/* <Col id="tableDiv" md={isFilter ? "9" : "12"}> */}
            <Col id="tableDiv" md="12">
              <Row>
                <Col md="10">
                  <div className="d-flex justify-content-between">
                    <h3 class="tab-title">Interview Listing</h3>
                  </div>
                </Col>

              </Row>

              <ScheduledInterviewListCard
                // searchFormData={{ ...searchFormData, page: tab }}
                // companyId={companyId}
                loader={scheduledInterviewListLoader}
              />

            

        <div
          style={{
            display: scheduledInterviewListLoader ? "none" : "contents",
          }}
        >
          
          <PaginationComponent
            totalCount={scheduledInterviewListResult?.itemCount}
            handlePagination={handlePagination}
            activePage={scheduledInterviewListResult?.activePage}
          />
        </div>
            </Col>

            
          </Row>
        </Container>
    </>
  )
}

export default ScheduledInterviewList;